html, body, #root {
  height: 100%;
  min-height: 100%;
}

html {
  font-family: sans-serif;
  font-size: 16px;
}

body {
  color: #232735;
  margin: 0;
  font-family: 'Gotham Rounded', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
}

th {
  background: #F8FAFC;
  border-bottom: 1px solid #E7E7E7;
  font-size: 16px;
  font-weight: 400;
  padding: 18px 5px;
  text-align: center;
  white-space: nowrap;
}

td {
  border-bottom: 1px solid #E7E7E7;
  font-size: 14px;
  padding: 12px 5px;
  text-align: center;
}

th:first-child,
td:first-child {
  padding-left: 32px;
  text-align: left;
}
th:first-child:has(input),
td:first-child:has(input) {
  padding-left: 0px !important;
  text-align: left;
  max-width: 14px;
}

th:last-child,
td:last-child {
  padding-right: 32px;
}

tr:last-child td {
  border-bottom: none;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

button {
  all: unset;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  padding: 8px 0;
}

h2 {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  padding: 8px 0;
}

h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
}

a {
  text-decoration: none;
}
